<template>
  <div>
    <div v-if="$vuetify.breakpoint.xs">
      <v-btn icon @click="onClicked">
        <v-icon v-text="icon" />
      </v-btn>
    </div>
    <div v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="onClicked">
            <v-icon v-text="icon" v-on="on" v-bind="attrs" />
          </v-btn>
        </template>
        {{ text }}
      </v-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: { default: "" },
    icon: {
      type: String,
      default: "mdi-help-circle",
    },
    onClicked: {
      type: Function,
      default: () => {
        console.log("button clicked");
      },
    },
  },
};
</script>
<style scoped>
.text-block {
  white-space: pre-line;
  color: black;
  overflow: hidden;
  display: block;
}
</style>
